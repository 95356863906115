import request from '@/utils/request'


// 查询操作记录列表
export function listUseHistory(query) {
  return request({
    url: '/door/door-use-history/list',
    method: 'get',
    params: query
  })
}

// 查询操作记录分页
export function pageUseHistory(query) {
  return request({
    url: '/door/door-use-history/page',
    method: 'get',
    params: query
  })
}

// 查询操作记录详细
export function getUseHistory(data) {
  return request({
    url: '/door/door-use-history/detail',
    method: 'get',
    params: data
  })
}

// 新增操作记录
export function addUseHistory(data) {
  return request({
    url: '/door/door-use-history/add',
    method: 'post',
    data: data
  })
}

// 修改操作记录
export function updateUseHistory(data) {
  return request({
    url: '/door/door-use-history/edit',
    method: 'post',
    data: data
  })
}

// 删除操作记录
export function delUseHistory(data) {
  return request({
    url: '/door/door-use-history/delete',
    method: 'post',
    data: data
  })
}
